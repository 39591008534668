<template>
  <div class="project-detail">
    <div class="container fluid">
      <!-- ========================= Project Detail ======================== -->
      <project-detail-header :project="project"></project-detail-header>
      <project-detail-content :project="project"></project-detail-content>

      <!-- ========================== Floor Plans ========================== -->
      <floor-plans
        class="mt-2"
        :floorPlans="floorPlans"
        :pagination="floorPlansPagination"
        :paginationParams.sync="floorPlanPaginationParams"
        :isLoading.sync="isfloorPlanLoading"
        :getFloorPlans="getFloorPlans"
        @toDetail="toFloorPlanDetail"
      >
        <template #floor-plan-card-action="{ floorPlan }">
          <fd-button
            class="w-100 main"
            @click="openAddDesignModal(floorPlan.id)"
          >
            <i class="fas fa-plus mr-1"></i>
            Add Design
          </fd-button>
        </template>
      </floor-plans>
    </div>
    <!-- ============================= Modals ============================== -->
    <modal v-model="addDesignModal.isShown" fullscreen>
      <add-design
        :floorPlanId="addDesignModal.floorPlanId"
        class="card"
        @submit="createDesign"
        @close="closeAddDesignModal"
      ></add-design>
    </modal>
  </div>
</template>

<script>
import { project as projectAPI, floorPlanDesign as designAPI } from "@/api";
import ProjectDetailHeader from "@/modules/Project/components/ProjectDetail/Header";
import ProjectDetailContent from "@/modules/Project/components/ProjectDetail/Content";
import FloorPlans from "@/modules/Project/components/FloorPlan/FloorPlans";
import { FloorPlanDesignModel } from "@/models";

export default {
  components: {
    ProjectDetailHeader,
    ProjectDetailContent,
    FloorPlans,
    AddDesign: () => import("@/components/FloorPlanDesigns/AddDesign")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      project: {},

      floorPlans: [],
      floorPlansPagination: {},
      floorPlanPaginationParams: {
        page: 1,
        perPage: 4
      },
      isfloorPlanLoading: false,

      addDesignModal: {
        isShown: false,
        floorPlanId: ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      this.$store.commit("setIsLoading", true);
      this.getFloorPlans();
      await this.getProjectDetail();
      this.$store.commit("setIsLoading", false);
    },
    toFloorPlanDetail(id) {
      this.$router.push({
        name: "ProjectFloorPlansDetails",
        params: {
          projectId: this.$route.params.id,
          id: id
        }
      });
    },
    openAddDesignModal(floorPlanId) {
      this.addDesignModal.isShown = true;
      this.addDesignModal.floorPlanId = floorPlanId;
    },
    closeAddDesignModal() {
      this.addDesignModal.isShown = false;
      this.addDesignModal.floorPlanId = "";
    },
    // ============================== API RELATED ==============================
    async getProjectDetail() {
      try {
        let data = await projectAPI.getProject(this.$route.params.id);

        this.project = this._.cloneDeep(data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        this.$reportError(error, "Get Project Detail");
        throw error;
      }
    },
    async getFloorPlans(filter) {
      this.isfloorPlanLoading = true;
      try {
        let filteredParam = this.$cleanQueryParam(filter);

        let data = await projectAPI.getFloorPlans(this.$route.params.id, {
          queries: {
            ...filteredParam
          },
          page: this.floorPlanPaginationParams.page,
          perPage: this.floorPlanPaginationParams.perPage
        });
        this.floorPlans = this._.cloneDeep(data.data);
        this.floorPlansPagination = this._.cloneDeep(data.meta.pagination);

        this.isfloorPlanLoading = false;
      } catch (error) {
        this.isfloorPlanLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    },
    async createDesign(payload) {
      console.log(payload);
      try {
        this.$store.commit("setIsLoading", true);
        await designAPI.createDesign(FloorPlanDesignModel.postPayload(payload));
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Floor plan design has been created succecssfully."
        });
        this.closeAddDesignModal();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to create floor plan design. Please try again later."
        });
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss"></style>
